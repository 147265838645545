import React from "react"
import  "../styles/global.scss"
import { Container, Col, Row } from 'reactstrap';
import Layout from "../layouts/index"
import { withPrefix } from "gatsby"
import { Helmet } from 'react-helmet';


const MembershipPage  = () => (
	<Layout>
		<Helmet>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <title>Interaid Pharmacy | Prime Membership</title>
          <meta name="title" content="Interaid Pharmacy | Prime Membership" />
		  <meta name="description" content="Find out more about Interaid Pharmacy Prime Membership now." />
          <link rel="canonical" href="www.interaidpharmacy.com.my/membership" />

        </Helmet>

		<Container fluid="true" className="page-title-block membership-page-title-block">
	        <Row>
	  			<p className="text-center text-white text-bold-500">Prime Membership</p>
	 		</Row>
	    </Container>
	

		<Container fluid="true" className="page-block text-center">
	   		<img src={withPrefix('/assets/img/member-card.png')} alt="Logo" width="300px" height="200px" class="img-fluid"/>

			<p className="text-left text-bold-700 font-20 padding-20">Membership benefits</p>
	    	
	    	
		<Row className="block-center">
			<Col md="4">
			<p><img src={withPrefix('/assets/img/member-1.png')} alt="Logo" width="100px" height="100px" class="img-fluid"/></p>
				<span className="member-benefit-title text-bold-700">Special in-store discount</span>
				<p>Enjoy 5% off on every order.</p>
			</Col>

			<Col md="4">
			<p><img src={withPrefix('/assets/img/member-2.png')} alt="Logo" width="100px" height="100px" class="img-fluid"/></p>

				<span className="member-benefit-title text-bold-700">Point Collection</span>
				<p>Earn 1 point for every RM 1 spent. </p>
			</Col>

			<Col md="4">
			<p><img src={withPrefix('/assets/img/member-3.png')} alt="Logo" width="100px" height="100px" class="img-fluid"/></p>

				<span className="member-benefit-title text-bold-700">Point Redeemption</span>
				<p>Redeem member points for gifts/vouchers.</p>
			</Col>

			
		</Row>

		<Row className="block-center">
			<Col md="4">
			<p><img src={withPrefix('/assets/img/member-4.png')} alt="Logo" width="100px" height="100px" class="img-fluid"/></p>

				<span className="member-benefit-title text-bold-700">Exclusive deals</span>
				<p>Enjoy member day offers, member exclusive vouchers and gifts during special occasion.</p>
			</Col>

			<Col md="4">
			<p><img src={withPrefix('/assets/img/member-5.png')} alt="Logo" width="100px" height="100px" class="img-fluid"/></p>

				<span className="member-benefit-title text-bold-700">Auto-renewal membership</span>
				<p>Auto renew yearly embership with only 200 points per annum.</p>
			</Col>

			<Col md="4">
			<p><img src={withPrefix('/assets/img/member-6.png')} alt="Logo" width="100px" height="100px" class="img-fluid"/></p>

				<span className="member-benefit-title text-bold-700">Birthday treat</span>
				<p>Birthday discount voucher for selected product categories and free basic health tests.</p>
			</Col>
		</Row>

		<p className="text-left text-bold-700 font-20 membership-title">How to sign up?</p>
		<p className="text-left membership-desc">
			Head over to our pharmacy counter with your IC to register for your first-year prime membership at only RM12 &nbsp;<span className="text-bold-500">OR</span>&nbsp;  spend at least RM300 on your total order to receive it for FREE.
			Upon sign-up, you will enjoy instant 5% discount on your purchase.
		</p>
		
		<p className="text-left text-bold-700 font-20 padding-20 membership-title">Term and conditions</p>
		<p className="text-left font-16">
			<ol>
			  <li>	This card is not transferrable and is the property of Interaid Pharmacy.</li>
				<li>The Interaid Pharmacy Prime Member Card is not a credit card or charge card.</li>
				<li>Upon presentation of the Interaid Pharmacy Prime Member Card, the member is entitled to a 5% discount on all regular priced products at Interaid Pharmacy outlet in Malaysia, but excluding certain promotional items.</li>
				<li>Interaid Pharmacy Prime Member Card/member name/NRIC must be presented before payment of each purchase in order to enjoy special discount.</li>
				 <li>Interaid Pharmacy Prime membership card will be automatically renewed if it achieves the prescribed qualified annual spending which is RM 200 and 200 points will be deducted automatically for such purpose.
				The date of auto-renewal shall commence on the first day of the thirteenth (13th) month.</li>
				
				<li>Free auto-renewal shall subject to the conditions if member cards that have since expired for more than a year are not entitled to this offer.</li>
				
				<li>In the event the membership expires, members shall have the option to either apply for a new Interaid Pharmacy Prime Member Card
				 or renew the membership at outlet counter.
				  In the case of application for a new member card, all the points and rebates accumulated from the
				  expired card shall not be carried forward to the new Interaid Pharmacy Prime Member Card and the points and 
				  rebates shall be null and void.</li>
				 <li>No reward points will be credited to Interaid Pharmacy Prime Member Card for any transactions made for extra services such as bill payment services or health screening services.</li>
				<li>Interaid pharmacy reserves the right to use and utilise the database information for marketing purposes.</li>
				<li>Interaid pharmacy reserves the right to omit, add or amend the Interaid Pharmacy Prime membership terms and conditions without prior notice.</li>
				<li>Interaid pharmacy reserves the right to deduct the balance of a member’s points accumulation in the event that:
				<ul>
					<li>Any point is suspected of being fraudulently recorded;</li>
					<li>Any point is recorded in error;</li>
					<li>Any point related to a transaction which has been cancelled or where a refund has been given</li>
					
				</ul>
				</li>
			</ol>
			
		</p>


		</Container>
	</Layout>
 )

export default MembershipPage

